<template>
  <div>
    <div class="funnel-kanban">
      <div class="stage-columns">
        <div class="stage" v-for="(step, index) in steps" :key="index">
          <FunnelColumn :step="step" :funnelId="funnelId" ref="FunnelColumn" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FunnelColumn from "./FunnelColumn.vue";

export default {
  name: "FunnelKanban",
  props: {
    steps: Array,
    funnelId: String,
  },
  components: { FunnelColumn },
  mounted() {
    this.$on("onSelectOptOrigin", this.emitSelectedOptOrigin);
  },
  methods: {
    emitSelectedOptOrigin(origin) {
      console.log("onSelectOptOrigin 555", origin, this.$refs.FunnelColumn);
      this.$refs.FunnelColumn.forEach((funnelCol) => {
        funnelCol.$emit("childEmitOptOrigin", origin);
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.funnel-kanban
  padding: 0 15px
  width: 100%
  overflow-x: auto
  .stage-columns
    display: flex
    align-items: flex-start
    .stage
      flex: 1
      position: relative
      background: #fff
      overflow: hidden
      width: 250px
      max-width: 250px
      min-width: 250px
      min-height: 800px
      border-right: 1px solid #ecf0f1
      margin: 0
      padding: 0
</style>
